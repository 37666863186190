const cardFile = (stripe) => {

    var elements = stripe.elements();
    var style = {
        base: {
            color: '#000000',
            fontWeight: 600,
            fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            ':focus': {
                color: '#424770',
            },

            '::placeholder': {
                color: '#9BACC8',
            },

            ':focus::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#fff',
            ':focus': {
                color: '#FA755A',
            },
            '::placeholder': {
                color: '#FFCCA5',
            },
        },
    } 

    var cardNumber = elements.create('cardNumber', { style });
    var cardExpiry = elements.create('cardExpiry', { style });
    var cardCvc = elements.create('cardCvc', { style });

    cardNumber.mount('#number-element');
    cardExpiry.mount('#expiration-element');
    cardCvc.mount('#cvc-element');



    var cardholderName = document.getElementById('cardholder-name');
    var setupForm = document.getElementById('setup-form');
    var clientSecret = setupForm.dataset.secret;

    setupForm.addEventListener('submit', async function(ev) {
        ev.preventDefault();

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
            billing_details: {
              name: document.getElementById('name-input').value,
            },
        });
    
        if (error) {
        console.error(error.message);
        alert(error.message);
        return;
        }

        const { setupIntent, error: confirmError } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: paymentMethod.id,
        });

        if (confirmError) {
            console.error(confirmError.message);
            alert(confirmError.message);
            return;
        }

        alert('Carte ajoutée avec succès !');

        window.location.href = '../payment_methods';
    });
};


export default cardFile
