import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.charging_station_id = this.element.dataset.id
    this.max = this.element.dataset.max
    this.loadLogs()
    this.poll()
  }

  poll() {
    this.interval = setInterval(() => {
      this.loadLogs()
    }, 2000)
  }

  loadLogs = () => {
    const url = this.max ? `/charge_network/charging_stations/${this.charging_station_id}/logs/poll?min_id=${this.max}` : `/charge_network/charging_stations/${this.charging_station_id}/logs/poll`;
    
    fetch(url, { 
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      },
      credentials: 'same-origin'
    }).then(response => {
      const maxId = response.headers.get('X-Logs-Max-Id');
      if (maxId) {
        this.max = maxId;
        this.element.setAttribute('data-max', maxId);
      }
      return response.text();
    })
    .then(html => {
        Turbo.renderStreamMessage(html);
    })
    .catch(error => console.error('Error fetching data:', error));
  }

  disconnect() {
    clearInterval(this.interval);
  }
}